import React from "react"
import Layout from "../../components/Layout"
import FormSection from "./components/FormSection"
import Hero from "./components/hero"

const Contact = () => {
  return (
    <Layout>
      <Hero />
      <FormSection />
    </Layout>
  )
}

export default Contact
